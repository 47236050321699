import React, { Component } from "react";
import { Flex, Image } from "rebass";
import FlexSection from "../sections/FlexSection";
import "../styles/experience.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WesternLogo from "../media/western1.png";
import BlackTraxLogo from "../media/bt.png";
import LawyerlyLogo from "../media/lawyerly.png";
import LyncworkLogo from "../media/lyncwork.png";

const WesternImage = () => <Image src={WesternLogo} className="western-logo" />;

const BlacktraxImage = () => <Image src={BlackTraxLogo} />;

const LawyerlyImage = () => <Image src={LawyerlyLogo} />;

const LyncworkImage = () => <Image src={LyncworkLogo} />;

class Experience extends Component {
  render() {
    return (
      <FlexSection bgColor="#012641">
        <h1 className="experience-title"> My Experience </h1>
        <VerticalTimeline className="timeline">
          <VerticalTimelineElement
            className="lyncwork-ft"
            contentStyle={{ background: "#F4FBFD" }}
            date="April 2022 - Present"
            dateClassName="date-font"
            iconStyle={{ background: "#F4FBFD", color: "#fff" }}
            icon={<LyncworkImage />}
          >
            <h3 className="timeline-title"> Full Stack Developer @ Lyncwork</h3>
            <h4 className="timeline-subtitle"> 📍 Toronto, ON </h4>
            <p className="timeline-description">
              Creating a solution to simplify HR management and enhance employee
              engagement.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="blacktrax-ft"
            contentStyle={{ background: "#F4FBFD" }}
            date="June 2019 - April 2022"
            dateClassName="date-font"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<BlacktraxImage />}
          >
            <h3 className="timeline-title"> Software Developer @ BlackTrax</h3>
            <h4 className="timeline-subtitle"> 📍 Toronto, ON </h4>
            <p className="timeline-description">
              Working on engineering a scalable software system to automate
              robotic camera control and talent tracking for large spaces.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="western"
            contentStyle={{ background: "#F4FBFD" }}
            date="Sept 2015 - June 2020"
            dateClassName="date-font"
            iconStyle={{ background: "#F4FBFD", color: "#fff" }}
            icon={<WesternImage />}
          >
            <h3 className="timeline-title"> Western University </h3>
            <h4 className="timeline-subtitle"> 📍 London, ON </h4>
            <p className="timeline-description">
              Graduated with a B.Sc in Computer Science, and a Minor in Game
              Development in 2020.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="lawyerly"
            contentStyle={{ background: "#F4FBFD" }}
            date="April 2018 - July 2018"
            dateClassName="date-font"
            iconStyle={{ background: "white", color: "#fff" }}
            icon={<LawyerlyImage />}
          >
            <h3 className="timeline-title"> Frontend Developer @ Lawyerly </h3>
            <h4 className="timeline-subtitle"> 📍 London, ON </h4>
            <p className="timeline-description">
              Created frontend React pages for a lawyer-to-client connection
              startup.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </FlexSection>
    );
  }
}

export default Experience;
