import React, { Component, useState, useRef } from "react";
import {Flex, Image} from 'rebass';
import FlexSection from '../sections/FlexSection';
import '../styles/chat.css';
import {Button, Tooltip} from '@material-ui/core';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {auth, firebase, firestore} from './FirebaseConfig';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #60B3D1 30%, #60B3D1 90%)',
    borderRadius: 3,
    border: 0,
    color: 'rgb(244, 251, 253)',
    
    padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const SendButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, rgb(244, 251, 253) 30%, rgb(244, 251, 253) 90%)',
    borderRadius: 3,
    border: 0,
    color: '#012641',
    
    padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const BlueTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#60B3D1",
    color: '#F4FBFD',
    fontFamily: "Raleway, sans-serif",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: '#60B3D1',
  }
}))(Tooltip);

function ChatMessage(props){
  const { text, uid, photoURL } = props.message;
  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  return (
    <Flex className={`message ${messageClass}`}>
      <Image className="img" src={photoURL}/>
      <p className="content-p"> {text} </p>
    </Flex>
  )
}

function Chat({ currentRoom }) {
  const {uid} = auth.currentUser;
  const [clickedRoom, setClickedRoom] = useState('Zgf3V1hf'); // default state with myself

  firestore.collection('users').doc(uid).get()
  .then((docSnapshot) => {
    if (docSnapshot.exists) {
      setAdmin(docSnapshot.data().admin);
    }
  });

  const [admin, setAdmin] = useState(false);
  const dummy = useRef(0);
  let messagesRef, query;

  const usersRef = firestore.collection('users');
  let queryUsers;

  if(admin){
    queryUsers = usersRef.orderBy('uid');
    messagesRef = firestore.collection('messages');
    query = messagesRef.where('room', '==', clickedRoom).orderBy('createdAt').limit(100);
  }
  else{
    messagesRef = firestore.collection('messages');
    query = messagesRef.where('room', '==', currentRoom).orderBy('createdAt').limit(100);
    queryUsers = '';
  }

  const [messages] = useCollectionData(query, {idField: 'id'});

  const [users] = useCollectionData(queryUsers, {idField: 'id'});
  console.log(users);

  const [formValue, setFormValue] = useState('');

  const sendMessage = async(e) => {
    e.preventDefault();
    const {uid, photoURL} = auth.currentUser;
    let value = formValue.trim();
    if(value){
      if(!admin)
      {
        await messagesRef.add({
          text: formValue,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid,
          photoURL,
          room: currentRoom
        })
      }
      else
      {
        await messagesRef.add({
          text: formValue,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid,
          photoURL,
          room: clickedRoom
        })
      }
    }
    
    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  const changeRoom = (room) => {
    setClickedRoom(room);
    // console.log("SELECTED ROOM ID: " + clickedRoom);
  }

  return (
    <FlexSection>
      <Flex className="inner-container">
        <BlueTooltip title="A fun little WIP :)" arrow>
          <h1 className="chat-title"> Chat With Me </h1>
        </BlueTooltip>
        <Flex>
          { admin ? 
            <Flex className="user-list"> 
              { users && users.map(usr =>  <StyledButton onClick={() => changeRoom(usr.room_id)}> {usr.displayName} </StyledButton> )}
            </Flex> : <div/> }
          <Flex className="chat-area">
            { messages && messages.map(msg => <ChatMessage key={msg.id} message={msg}/> )}
            <div ref={dummy} />
          </Flex>
        </Flex>
        <form className="form" onSubmit={sendMessage}>
          <input className="input" value={formValue} onChange={(e) => setFormValue(e.target.value)}/>
          <SendButton type="submit"> Send </SendButton>
          <StyledButton variant="contained" target="_blank" onClick={() => auth.signOut()}>Sign Out</StyledButton>
        </form>
      </Flex>
    </FlexSection>
  )
}

export default Chat