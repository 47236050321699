import React, { Component } from "react";
import '../styles/flexsection.css';
import {Flex} from 'rebass';

const FlexSection = ({children, bgColor, id}) => (
  <Flex className="flex-section" style={{backgroundColor: bgColor}} id={id} >
    { children }
  </Flex>
)

export default FlexSection