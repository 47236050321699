import React, { Component } from "react";
import '../styles/projects.css';
import {Flex, Image} from 'rebass';
import {Tiles} from '@rebass/layout';
import FlexSection from '../sections/FlexSection';
import { Grid, Card, CardMedia, CardContent, CardActions, CardActionArea, Button, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from 'react-reveal/Zoom';

const useStyles = makeStyles({
  root: {
    width: 700,
    maxWidth: 700,
    height: 400,
    maxHeight: 400,
    background: 'rgba(72, 72, 74, 0.5)',
    backdropFilter: 'blur(5px)',
    background: '#FFFFFF',
    border: '0.25px solid #E6F9FF',
    boxSizing: 'border-box',
    boxShadow: '6px 6px 17px rgba(183, 240, 255, 0.75)',
    borderRadius: '5px 5px 0 0',
  },
  media: {
    height: 200,
    maxHeight: 200,
  },
  text: {
    fontFamily: "Raleway, sans-serif",
    fontWeight: 'normal',
    color: 'black',
  },
  projectName: {
    fontFamily: "Raleway, sans-serif",
    fontWeight: 'normal',
    color: 'black',
  }
});

const ProjectCard = ({title, path, description, link}) =>(
  <Card className={useStyles().root} raised >
    <CardActionArea onClick={() => window.open(link)}>
      <CardMedia 
      className={useStyles().media}
      image={path}
      title={title} 
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2" className={useStyles().projectName}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={useStyles().text}>
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
)

class Projects extends Component {
  render() {
    return (
      <FlexSection bgColor="#F4FBFD">
        <Flex className="projects-inner-container">
          <h1 className="projects-title"> Featured Projects 👨🏻‍💻 </h1>
          <Tiles padding="5%" columns={[1, null, 3]} className="tiles">
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="🎮 Wardle" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/829/516/datas/gallery.jpg" 
              description="A project built and inspired by the viral game: Wordle. Play Wordle against friends and family. Pick a username, share the
              invite link, and play Wordle rounds to see who can guess the 5-letter word first. Built using React, Node, and Socket IO." 
              link="https://word-battle.herokuapp.com/"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="💻 Stage Fight" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/746/889/datas/original.png" 
              description="A service leveraging Machine Learning to help people prepare for public speeches and presentations. 
              Stage Fight gives real-time feedback as it analyzes your movements and speech patterns. 'Fight your Stage Fright!' " 
              link="https://devpost.com/software/stage-fight"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="📱 SeeFood" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/728/365/datas/original.png" 
              description="A mobile app that allows users to view food portions in Augmented Reality (AR) before ordering online. 
              The project includes a mock up restaurant website with QR links to food products, a slackbot for suggesting food takeout options, 
              and viewing and ordering food with SMS notifications." 
              link="https://devpost.com/software/seefood-7obsd4"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="🎮 Bloom" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/570/997/datas/original.png" 
              description="An online multiplayer gardening game that promotes communal collaboration and relaxation. The game is synced to real time weather using 
              The Weather Network API. (Playable in browser!)" 
              link="https://devpost.com/software/bloom-k50zoy"/>
            </Flex>

            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="💻 PawPrint" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/553/597/datas/original.png" 
              description="A service to help owners find their lost pets using image recognition and video feeds from public cameras. 
              Users can specify between the supported animal types of 'dog or cat,' and PawPrint will 
              message the owner VIA SMS if a match is found through the video analysis." 
              link="https://devpost.com/software/pawprint"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="🎮 Slime Time" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/746/917/datas/original.png" 
              description="A video game developed for the game jam theme of Mental Health awareness. Players take care of cute slimes on a relaxing farm, 
              feeding, bathing, and petting them. (Playable in browser!)" 
              link="https://devpost.com/software/slime-time"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="🎮 Ninjam" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/756/998/datas/original.png" 
              description="A local 4-player game where players must capture and hold a flag from each other to accumulate points. Each player
              is a ninja with access to ninja stars and stun traps. The first to the max points wins. (Playable in browser!)" 
              link="https://devpost.com/software/ninjam"/>
            </Flex>

            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="🎮 ThreeWay FreeWay" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/588/612/datas/original.png" 
              description="A 3-player local game where flight, shoot, and acceleration controls switch every 8 beats in the game music. Each 
              player is in charge of one control aspect, and you must work together through the chaos to escape the impending wall of death." 
              link="https://devpost.com/software/threeway-freeway"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="💻 Elle" 
              path="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/539/399/datas/original.png" 
              description="A real-time communication service that matches people of differing languages in chat rooms, and automatically translates 
              conversations for the participants. This web app promotes learning and engaging with different cultures across the globe." 
              link="https://devpost.com/software/elle-a-language-exchange"/>
            </Flex>
            <Flex flex="1" justifyContent="center">
              <ProjectCard 
              title="🎮 Assemblance" 
              path="https://img.itch.zone/aW1nLzMwNzc3NzMucG5n/347x500/V3qfiv.png" 
              description="An online co-op game for Mental Health awareness, where players must use verbal communication to guide eachother to the end goal. Each player 
              cannot see their own character, but can see the other player's; they must talk out their problems to overcome the obstacles presented before them." 
              link="https://yuan.itch.io/assemblance"/>
            </Flex>
          </Tiles>
        </Flex>
      </FlexSection>
    )
  }
}

export default Projects