import React, { Component, useState } from "react";
import {Flex} from 'rebass';
import FlexSection from '../sections/FlexSection';
import Section from '../sections/Section';
import '../styles/signin.css';
import { Button, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth, firebase, firestore} from './FirebaseConfig';

const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #60B3D1 30%, #60B3D1 90%)',
    borderRadius: 3,
    border: 0,
    color: 'rgb(244, 251, 253)',
    height: 48,
    padding: '0 30px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const BlueTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#60B3D1",
    color: '#F4FBFD',
    fontFamily: "Raleway, sans-serif",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: '#60B3D1',
  }
}))(Tooltip);

function SignIn() {
    var makeId = (length) => {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() * 
     charactersLength)));
       }
       return result.join('');
    }

    const signIn = () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      auth.signInWithPopup(provider);  
    }

    return (
      <FlexSection>
      <Flex className="inner-container">
        <BlueTooltip title="A fun little WIP :)" placement='top' arrow>
          <h1 className="chat-title"> Chat With Me </h1>
        </BlueTooltip>
        <BlueTooltip title="Sign in to send me a message." arrow>
        <StyledButton variant="contained" target="_blank" onClick={signIn}>Sign In With Google</StyledButton>
        </BlueTooltip>
      </Flex>
      </FlexSection>
    )
}

export default SignIn