import React, { Component } from "react";
import { Flex, Image } from "rebass";
import Section from "../sections/Section";
import "../styles/about.css";
import picOfMe from "../media/me.png";
import meArrow from "../media/mearrow.png";
import Fade from "react-reveal/Fade";

class About extends Component {
  render() {
    const { title, skills, avatar } = this.props;

    return (
      <Section bgColor="#F4FBFD">
        <Flex className="inner-container" backgroundColor="#F4FBFD">
          <Flex className="about-container" backgroundColor="#F4FBFD">
            <Flex className="about-description">
              <Fade left duration={2000}>
                <Flex flexDirection="column" width="fit-content">
                  <h1 className="about-title">
                    {" "}
                    About Me{" "}
                    <a onClick={() => console.log("GO TO EASTER EGG PAGE")}>
                      🕹️
                    </a>{" "}
                  </h1>
                  <span className="underline" />
                </Flex>
                <h2 className="about-paragraph">
                  <p>
                    {" "}
                    Hi, I'm Justin, a <b>software developer</b> in Toronto,
                    Canada.{" "}
                  </p>
                  <p>
                    {" "}
                    I have worked on robotic camera automation at BlackTrax
                    using <b>C# and Unity</b>, as well as done <b>Full Stack</b>{" "}
                    development at Lyncwork. I received my B.Sc in Computer
                    Science and minor in Video Game Design from{" "}
                    <b>Western University</b> in June 2020. I am looking for
                    exciting, new opportunities to grow my skillset and
                    constantly learn! I built this website using <b>React.js</b>{" "}
                    and <b>Google Firebase</b>, and am adding things as I go.{" "}
                  </p>
                  <p>
                    {" "}
                    If you have any questions, feel free to reach out to me at
                    my email: <a className="email">
                      oryzajustin@gmail.com
                    </a>.{" "}
                  </p>
                </h2>
              </Fade>
            </Flex>
            <Flex className="image-container">
              <Image className="me-pic" src={picOfMe} />
            </Flex>
          </Flex>
        </Flex>
      </Section>
    );
  }
}

export default About;
