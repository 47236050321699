import React, { Component } from "react";
import "../styles/landing.css";
import { Flex } from "rebass";
import Section from "../sections/Section";
import Resume from "../resume/resume.pdf";
import Typed from "react-typed";
import { Button } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const StyledButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #60B3D1 30%, #60B3D1 90%)",
    borderRadius: 3,
    border: 0,
    color: "rgb(244, 251, 253)",
    height: 48,
    padding: "0 30px",
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

class Landing extends Component {
  componentDidMount() {
    var rand = Math.floor(Math.random() * 3);
    switch (rand) {
      case 0:
        window.VANTA.NET({
          el: "#vanta",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundColor: 0x012641,
          color: 0xffffff,
          spacing: 20,
        });
        break;
      case 1:
        window.VANTA.HALO({
          el: "#vanta",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          backgroundColor: 0x012641,
        });
        break;
      case 2:
        window.VANTA.DOTS({
          el: "#vanta",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          backgroundColor: 0x012641,
          color: 0x20ffd7,
          color2: 0x1471b3,
          scale: 1.0,
          scaleMobile: 1.0,
        });
        break;
      default:
        window.VANTA.NET({
          el: "#vanta",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundColor: 0x012641,
          color: 0xffffff,
          spacing: 20,
        });
        break;
    }
  }

  render() {
    const { intro, title, name, subtitle } = this.props;

    return (
      <Section bgColor="#012641" id="vanta">
        <Flex className="landing-description">
          <Flex className="sub-container">
            <h1 className="small">{intro}</h1>
            <Fade>
              <h2 className="big">{name}</h2>
            </Fade>
            <Typed
              className="medium"
              strings={[
                title + " @ Lyncwork",
                "BSc Major in Computer Science",
                "Minor in Game Design",
                "Video Game Enthusiast",
              ]}
              typeSpeed={40}
              backSpeed={50}
              loop
            />
            <h1
              className="github-text"
              onClick={() => window.open("https://github.com/oryzajustin")}
            >
              <FontAwesomeIcon icon={faGithub} /> {subtitle}
            </h1>
            <Flex>
              <StyledButton variant="contained" target="_blank" href={Resume}>
                Resume
              </StyledButton>
            </Flex>
          </Flex>
        </Flex>
      </Section>
    );
  }
}

export default Landing;
