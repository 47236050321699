import React, { Component } from "react";
import '../styles/section.css';
import {Flex} from 'rebass';

const Section = ({children, bgColor, id}) => (
  <Flex className="section" style={{backgroundColor: bgColor}} id={id} >
    { children }
  </Flex>
)

export default Section