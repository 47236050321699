import { useState, useRef } from "react";
import logo from "../logo.svg";
import Header from "../components/Header";
import Landing from "../components/Landing";
import About from "../components/About";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import SignIn from "../components/SignIn";
import Chat from "../components/Chat";
import { Element } from "react-scroll";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { auth, firebase, firestore } from "../components/FirebaseConfig";
require("dotenv").config();

function App() {
  var makeId = (length) => {
    var result = [];
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join("");
  };

  const [user] = useAuthState(auth);

  const [currentRoom, setCurrentRoom] = useState("");

  var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const { uid, displayName } = auth.currentUser;
      var userRef = firestore.collection("users").doc(auth.currentUser.uid);

      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Document data:", doc.data()); // get single room id
            setCurrentRoom(doc.data().room_id);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            console.log("WE DONT EXIST");
            var roomID = makeId(8);
            firestore
              .collection("users")
              .doc(uid)
              .set(
                {
                  uid: uid,
                  room_id: roomID,
                  admin: false,
                  displayName: displayName,
                },
                { merge: true }
              )
              .then(() => {
                setCurrentRoom(roomID);
              });
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  });

  return (
    <div>
      <Header />
      <Element name="landing">
        <Landing
          intro="Hi, I'm"
          name="Justin Koh"
          title="Full Stack Developer"
          subtitle="github.com/oryzajustin"
        />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="experience">
        <Experience />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
      <Element name="chat">
        {user ? <Chat currentRoom={currentRoom} /> : <SignIn />}
      </Element>
    </div>
  );
}

export default App;
