import React, { Component } from "react";
import { Link, scroller } from "react-scroll";
import '../styles/header.css';
import { Flex } from 'rebass';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: 'landing',
    }
  }

  scrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  render() {
    return (
     <Flex className="header" backgroundColor="#012641">
      <Link 
      className="header-link"
      activeClass="active"
      to="about"
      spy={true}
      smooth={true}
      duration={500}
      >
        About
      </Link>
      <Link className="header-link"
      activeClass="active"
      to="experience"
      spy={true}
      smooth={true}
      duration={500}
      >
        Experience
      </Link>
      <Link className="header-link"
      activeClass="active"
      to="projects"
      spy={true}
      smooth={true}
      duration={500}
      >
        Projects
      </Link>
      <Link className="header-link"
      activeClass="active"
      to="chat"
      spy={true}
      smooth={true}
      duration={500}
      >
        Chat
      </Link>
     </Flex>
    )
  }
}

export default Header